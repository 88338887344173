import React from 'react';
import { Check } from 'lucide-react';

const IncentiveSection = ({ t }) => {
  return (
    <section className="py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center">
          {t('earlyAccessIncentiveTitle')}
        </h2>
        <div className="max-w-2xl mx-auto">
          {[1, 2, 3].map((num) => (
            <div 
              key={num}
              className="flex items-center mb-4"
            >
              <Check className="shrink-0 text-blue-600 mr-3" size={20} />
              <p className="text-lg">
                {t(`earlyAccessIncentive${num}`)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default IncentiveSection;