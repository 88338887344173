import HomeArticle from './HomeArticle';

// Home component
const Home = ({ t }) => {
    return (
        <div>
            <h1 className="text-2xl font-bold mb-4">{t('app_home_title')}</h1>
            <p>{t('app_home_description')}</p>
            <HomeArticle t={t} />
            <HomeArticle t={t} />
        </div>
    );
};

export default Home;