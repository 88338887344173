import { getAnalyticsInstance } from "./firebaseSetup";

export const logEvent = (eventName, eventParams) => {
    const consent = localStorage.getItem("analytics-consent");

    if (consent === "accepted") {
        const analytics = getAnalyticsInstance();
        const { logEvent } = require("firebase/analytics");
        logEvent(analytics, eventName, eventParams);
    } else {
        console.log("Analytics not allowed by user");
    }
};
