import React, { useState } from 'react';
import { Bell, Menu, X } from 'lucide-react';

const ResponsiveNav = ({ currentPage, setCurrentPage, notifications, t }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="relative">
      {/* Main Navigation Bar */}
      <nav className="bg-gray-800 text-white h-24">
        <div className="container mx-auto px-4 h-full">
          <div className="flex items-center justify-between h-full">
            {/* Left Side - Hamburger */}
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="lg:hidden p-2 hover:bg-gray-700 rounded-md"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>

            {/* Center - Logo and Title */}
            <div className="flex items-center space-x-4 mx-auto">
              {/* <img
                src="logo-bell-law-removebg-preview.png"
                alt="LawTracker Logo"
                className="h-20"
              /> */}
              <span className="text-2xl tracking-wide">{t('app_name')}</span>
            </div>

            {/* Desktop Menu - Right Side */}
            <div className="hidden lg:flex items-center space-x-8">
              <button
                className={`hover:text-gray-300 ${
                  currentPage === 'home' ? 'font-bold' : ''
                }`}
                onClick={() => setCurrentPage('home')}
              >
                {t('app_nav_home')}
              </button>
              <button
                className={`hover:text-gray-300 ${
                  currentPage === 'options' ? 'font-bold' : ''
                }`}
                onClick={() => setCurrentPage('options')}
              >
                {t('app_nav_options')}
              </button>
              <button
                className={`hover:text-gray-300 ${
                  currentPage === 'about' ? 'font-bold' : ''
                }`}
                onClick={() => setCurrentPage('about')}
              >
                {t('app_nav_about')}
              </button>
              <button
                className={`hover:text-gray-300 ${
                  currentPage === 'notifications' ? 'font-bold' : ''
                }`}
                onClick={() => setCurrentPage('notifications')}
              >
                <div className="relative">
                  <Bell size={24} />
                  {notifications > 0 && (
                    <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full px-2 py-1 text-xs">
                      {notifications}
                    </span>
                  )}
                </div>
              </button>
            </div>

            {/* Empty div for layout balance on mobile */}
            <div className="w-10 lg:hidden"></div>
          </div>
        </div>
      </nav>

      {/* Mobile Menu Overlay */}
      {isMenuOpen && (
        <div className="lg:hidden z-10">
          {/* Dark overlay */}
          <div 
            className="fixed inset-0 bg-black bg-opacity-50"
            onClick={() => setIsMenuOpen(false)}
          />
          
          {/* Menu panel */}
          <div className="fixed top-24 left-0 right-0 bg-gray-800 shadow-lg p-4  z-10">
            <div className="flex flex-col space-y-4 z-10">
              <button
                className={`text-left px-4 py-2 hover:bg-gray-700 rounded ${
                  currentPage === 'home' ? 'font-bold' : ''
                }`}
                onClick={() => {
                  setCurrentPage('home');
                  setIsMenuOpen(false);
                }}
              >
                {t('app_nav_home')}
              </button>
              <button
                className={`text-left px-4 py-2 hover:bg-gray-700 rounded ${
                  currentPage === 'options' ? 'font-bold' : ''
                }`}
                onClick={() => {
                  setCurrentPage('options');
                  setIsMenuOpen(false);
                }}
              >
                {t('app_nav_options')}
              </button>
              <button
                className={`text-left px-4 py-2 hover:bg-gray-700 rounded ${
                  currentPage === 'about' ? 'font-bold' : ''
                }`}
                onClick={() => {
                  setCurrentPage('about');
                  setIsMenuOpen(false);
                }}
              >
                {t('app_nav_about')}
              </button>
              <button
                className={`text-left px-4 py-2 hover:bg-gray-700 rounded ${
                  currentPage === 'notifications' ? 'font-bold' : ''
                }`}
                onClick={() => {
                  setCurrentPage('notifications');
                  setIsMenuOpen(false);
                }}
              >
                <div className="flex items-center">
                  <Bell size={24} />
                  {notifications > 0 && (
                    <span className="ml-2 bg-red-500 text-white rounded-full px-2 py-1 text-xs">
                      {notifications}
                    </span>
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResponsiveNav;