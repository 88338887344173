import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  
  // Function to get language from URL parameter, localStorage, or default
  const getInitialLanguage = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlLang = urlParams.get('lang'); // Get 'lang' from URL, e.g., ?lang=en
    const storedLang = localStorage.getItem('language');
    
    // URL parameter has the highest priority, then local storage, then default 'en'
    return urlLang || storedLang || 'en';
  };

  const [selectedLanguage, setSelectedLanguage] = useState(getInitialLanguage);
  const [isOpen, setIsOpen] = useState(false);
  let closeTimeoutRef = React.useRef(null);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
    setSelectedLanguage(lng);
    setIsOpen(false);
  };

  const handleMouseEnter = useCallback(() => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
    setIsOpen(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    closeTimeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 300); // 300ms delay before closing
  }, []);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
    return () => {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
    };
  }, [selectedLanguage, i18n]);

  return (
    <nav className="w-200 px-6 py-4 flex justify-end absolute top-0 right-0 z-10">
      <div 
        className="relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <button
          className={`
            bg-transparent text-white 
            border border-white/20 rounded 
            px-4 py-2
            hover:border-white/40 
            transition-all duration-200 
            flex items-center gap-2 
            min-w-[120px]
            justify-between
          `}
        >
          <span>{selectedLanguage === 'en' ? '🇬🇧 English' : '🇷🇴 Română'}</span>
          <svg 
            className={`w-4 h-4 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2"
          >
            <path d="M6 9l6 6 6-6" />
          </svg>
        </button>

        {isOpen && (
          <div 
            className="
              absolute top-full mt-1 
              bg-slate-800 
              border border-white/10 
              rounded-lg 
              overflow-hidden 
              shadow-lg 
              min-w-[120px]
              backdrop-blur-sm
            "
          >
            <button 
              onClick={() => changeLanguage('en')} 
              className={`
                w-full px-4 py-2 
                text-left text-white 
                hover:bg-slate-700/50
                transition-colors duration-200
                flex items-center gap-2
                ${selectedLanguage === 'en' ? 'bg-slate-700/50' : ''}
              `}
            >
              🇬🇧 English
            </button>
            <button 
              onClick={() => changeLanguage('ro')} 
              className={`
                w-full px-4 py-2 
                text-left text-white 
                hover:bg-slate-700/50
                transition-colors duration-200
                flex items-center gap-2
                ${selectedLanguage === 'ro' ? 'bg-slate-700/50' : ''}
              `}
            >
              🇷🇴 Română
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default LanguageSwitcher;