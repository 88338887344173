import React, { useState } from 'react';
import { Bell } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import Home from './pwapp/Home';
import ResponseiveNav from './pwapp/ResponsiveNav';
import InstallAppButton from './pwapp/InstallAppButton';

// Options component  
const Options = ({ t }) => {
    const [theme, setTheme] = useState('light');

    const toggleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light');
    };

    return (
        <div>
            <h1 className="text-2xl font-bold mb-4">{t('app_options_title')}</h1>
            <div className="mb-4">
                <label className="block font-medium mb-2" htmlFor="theme">{t('app_theme_label')}</label>
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        id="theme"
                        checked={theme === 'dark'}
                        onChange={toggleTheme}
                        className="mr-2"
                    />
                    <span>{theme === 'dark' ? t('app_theme_dark') : t('app_theme_light')}</span>
                </div>
            </div>
        </div>
    );
};

// Notifications component
const Notifications = ({ t }) => {
    return (
        <div>
            <h1 className="text-2xl font-bold mb-4">{t('app_notifications_title')}</h1>
            {/* Add notifications content here */}
        </div>
    );
};

// About component
const About = ({ t }) => {
    return (
        <div>
            <h1 className="text-2xl font-bold mb-4">{t('app_about_title')}</h1>
            <p>{t('app_description')}</p>
        </div>
    );
};

// App component
const App = () => {
    const [currentPage, setCurrentPage] = useState('home');
    const [notifications, setNotifications] = useState(5); // Example number of notifications

    const { t } = useTranslation();

    const renderPage = () => {
        switch (currentPage) {
            case 'options':
                return <Options t={t} />;
            case 'notifications':
                return <Notifications t={t} />;
            case 'about':
                return <About t={t} />;
            default:
                return <Home t={t} />;
        }
    };

    return (
        <div className="flex flex-col h-screen  font-['Space_Grotesk']">
            <ResponseiveNav currentPage={currentPage} setCurrentPage={setCurrentPage} notifications={notifications} t={t}/>

            {/* Content */}
            <div className="flex-1 p-6">{renderPage()}</div>
            <InstallAppButton />
        </div>
    );
};

export default App;
