import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import SubEarlyAccess from './SubEarlyAccess';
import MainApp from './MainApp';
import LanguageSwitcher from './components/LanguageSwitcher';

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <div>
          <LanguageSwitcher />
          <Routes>
            <Route path="/" element={<SubEarlyAccess />} />
            <Route path="/app" element={<MainApp />} />
          </Routes>
        </div>
      </Router>
    </I18nextProvider>
  );
};

export default App;