import React from 'react';

const KeyFeature = ({ icon, title, description, index }) => {
  return (
    <div 
      key={index} 
      className="bg-white/10 backdrop-blur-lg rounded-xl p-8 text-white 
        hover:bg-white/20 transition-all duration-300
        relative
        before:absolute before:inset-0 before:rounded-xl before:transition-all
        before:opacity-0 hover:before:opacity-100
        isolate before:-z-10"
    >
      <div className="mb-6">{icon}</div>
      <h3 className="text-2xl font-semibold mb-4">{title}</h3>
      <p className="text-blue-100 text-lg">{description}</p>
    </div>
  );
}

export default KeyFeature;