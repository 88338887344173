import React from 'react';

const BottomCTA = ({ email, setEmail, handleSubmit, t }) => {
  return (
    <div className="justify-center max-w-3xl mx-auto">
        <h2 className="text-3xl font-bold mb-8">{t('finalCTASectionTitle')}</h2>
          <form onSubmit={handleSubmit} className="flex flex-col md:flex-row gap-4 justify-center mb-8">
            <input
              type="email"
              placeholder={t('emailPlaceholder')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="
                flex-1 
                px-8 
                py-5 
                rounded-lg 
                text-lg 
                focus:outline-none 
                focus:ring-2 
                focus:ring-blue-300 
                text-black
              "
              required
            />
            <button type="submit" 
            className="
                bg-gradient-to-r 
                from-blue-500 
                to-pink-500 
                text-white 
                px-5
                py-5 
                rounded-lg 
                text-sm 
                font-semibold 
                transition-colors 
                duration-200 
                whitespace-nowrap 
                min-w-[220px]
                hover:font-normal
                hover:bg-gradient-to-r
                hover:from-white
                hover:to-white
                hover:text-blue-500
                hover:shadow-xl
                hover:border-blue-500
                hover:border-1
            ">{t('finalCTAButton')}</button>
          </form>
    </div>
  );
};

export default BottomCTA;