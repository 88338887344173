import React from 'react';

const VerticalCTA = ({ email, setEmail, handleSubmit, t }) => {
  return (
    <div className="flex flex-col sm:flex-row gap-4 justify-center max-w-3xl mx-auto">
      <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-4 w-full">
        <input
          type="email"
          placeholder={t('emailPlaceholder')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="
            flex-1 
            px-8 
            py-5 
            rounded-lg 
            text-lg 
            focus:outline-none 
            focus:ring-2 
            focus:ring-blue-300 
            text-black
          "
          required
        />
        <button 
          type="submit" 
          className="
            bg-gradient-to-r 
            from-blue-500 
            to-pink-500 
            text-white 
            px-12 
            py-5 
            rounded-lg 
            text-lg 
            font-semibold 
            transition-colors 
            duration-200 
            whitespace-nowrap 
            min-w-[300px]
            hover:font-normal
            hover:bg-gradient-to-r
            hover:from-white
            hover:to-white
            hover:text-black
            hover:shadow-xl
            hover:border-blue-500
            hover:border-1
          "
        >
          {t('heroCTAButton')}
        </button>
      </form>
    </div>
  );
};

export default VerticalCTA;