import React, { useState } from 'react';

const HomeArticle = ({ t }) => {
    const [showDetails, setShowDetails] = useState(false);

    const toggleDetails = () => {
        setShowDetails(!showDetails);
    };

    return (
        <div className="mt-4">
            <div className="text-2xl font-bold mb-4">
                <a href="https://monitoruloficial.ro/e-monitor/" className="hover:underline text-white">
                    <h1 className="text-white">Curtea Constituțională menține cerințele stricte de autorizare pentru construcții</h1>
                </a>
            </div>
            <button onClick={toggleDetails} className="text-blue-500 hover:underline">
                {showDetails ? 'Show Less' : 'Show More'}
            </button>
            {showDetails && (
                <p className="mt-2 text-white">
                    Detalii: Curtea Constituțională a României a menținut constituționalitatea Articolului 28 din Legea nr. 50/1991, care impune termene stricte pentru obținerea autorizațiilor de construcție. Decizia vine în urma unor contestații privind flexibilitatea termenelor de conformare și obligația demolării construcțiilor neautorizate în cazul neîndeplinirii acestor termene.
                    <br />
                    De ce este important: Această decizie întărește controlul reglementar asupra proiectelor de construcții, asigurând că lucrările neautorizate sunt fie demolate, fie aduse în legalitate într-un interval de timp strict reglementat. Decizia subliniază poziția Curții de a limita discreția administrativă în acordarea extinderilor de termen, având un impact asupra proprietarilor și dezvoltatorilor imobiliari.
                    <br />
                    Rezumat: Decizia Curții solidifică o abordare rigidă a conformității construcțiilor, punând accent pe respectarea legii și limitând flexibilitatea administrativă.
                    <br />
                    <div className="text-sm text-gray-600 hover:text-gray-400">Publicat pe 1 ianuarie 2024</div>
                </p>
            )}
        </div>
    );
};

export default HomeArticle;